import styled from 'libs/styled';

/**
 * Predefined PaddingWrappers used by FlexibleWrapper to wrap its content with correct padding.
 * Add/alter wrappers depending on your needs.
 */

const FlexiblePaddingWrapper = styled('div')`
    width: 100%;
    height: 100%;
    padding: 32px 0;
`;

export default FlexiblePaddingWrapper;
