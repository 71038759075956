/* eslint-disable camelcase */
import Flexible from 'components/contentBlocks/Flexible';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { mediaQueries } from 'config/theme/breakpoints';
import { textColorProp } from 'utils/proptypes/modules/styleProps';
import transformButtons from 'libs/wordpress/utils/transformButtons';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

const CallToActionHeroTransformer = ({
    content = {},
    headingSize = 'lg',
    useGridWrapper = false,
    useMaxWidth = true,
}) => {
    const { background_media_flexible: backgroundFlexible, buttons, heading, tag, text_color: textColor } = content;

    // Transfrom background data
    const isAboveSm = useAboveBreakpoint('sm');
    const transformedBackground = transformFlexibleBackground(backgroundFlexible);
    const mobileBackground = transformedBackground.mobile;
    const desktopBackground = transformedBackground.desktop;

    // Transfrom button data
    const transformedButtons = buttons
        ? transformButtons(buttons, { theme: textColor === 'white' ? 'outlinedWhite' : 'outlinedBlack' })
        : null;

    return (
        <Flexible
            background={isAboveSm ? desktopBackground : mobileBackground}
            backgroundRatio={[3 / 4, null, 4 / 3]}
            backgroundType={isAboveSm ? desktopBackground.type : mobileBackground.type}
            buttonListStyle={{ pr: ['38px', null, '0'] }} // Prevent overlaping with scroll icon
            buttons={transformedButtons}
            heading={heading ? decodeHTMLEntities(heading.text) : null}
            headingSize={headingSize}
            headingTag={heading ? heading.type : null}
            mediaQuery={mediaQueries.fullWidth}
            tag={decodeHTMLEntities(tag)}
            textColor={textColor}
            useGridWrapper={useGridWrapper}
            useMaxWidth={useMaxWidth}
            xAlignContent="left"
            yAlignContent="bottom"
        />
    );
};

CallToActionHeroTransformer.propTypes = {
    content: PropTypes.shape({
        background_media_flexible: PropTypes.exact({
            mobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
            desktop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
        }).isRequired,
        heading: PropTypes.object,
        buttons: PropTypes.arrayOf(PropTypes.object),
        tag: PropTypes.string,
        text_color: textColorProp,
    }),
    headingSize: PropTypes.string,
    useGridWrapper: PropTypes.bool,
    useMaxWidth: PropTypes.bool,
};

export default CallToActionHeroTransformer;
