import { backgroundFlexibleProp, backgroundTypeProp } from 'utils/proptypes/modules/mediaProps';
import {
    horizontalAlignmentProp,
    paddingOptionsProps,
    textColorProp,
    verticalAlignmentProp,
} from 'utils/proptypes/modules/styleProps';

import BackgroundSwitch from 'components/background/BackgroundSwitch';
import FlexibleContentPosition from 'components/wrappers/FlexibleWrapper/FlexibleContentPosition';
import FlexiblePaddingWrapper from 'components/wrappers/FlexibleWrapper/FlexiblePaddingWrapper';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'config/theme/colors';
import styled from 'libs/styled';

const TextColorWrapper = styled('div')`
    height: 100%;
    width: 100%;
`;

const WrapperLink = styled(Link)`
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:visited {
        color: currentColor;
    }
`;

const Overlay = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: ${colors.black};
    opacity: 0.1;
`;

/**
 * Used to apply a background, padding, position content and much more.
 * Mainly used contentBlocks/Flexible but can be used in other contexts aswell.
 *
 * @param {object||string} background - A video/image object or a color as a string.
 * @param {string} backgroundType - The type of background that should be used; video, image or color.
 * @param {node} children - JSX children
 * @param {array} customGridSize - Pass a custom variable to ModuleGridWrapper used inside FlexibleContentPosition to overwrite the default.
 * @param {array} mediaQuery - Used if background is a image, can also be a part of the background object.
 * @param {node} paddingWrapper - A JSX component that wraps children. Main use is to add padding around the children. See ./FlexiblePaddingWrapper.js
 * @param {string} textColor - Color as a string
 * @param {bool} useGridWrapper - Should the children be wrapped by ModuleGridWrapper.
 * @param {bool} useMaxWidth - Should the children be wrapped by MaxWidthWrapper.  Can only be used if useGridWrapper also is true.
 * @param {bool} useOverlay - Should the background be covered by a darker overlay.
 * @param {string} wrapperLink - A url as a string that wraps the entire component.
 * @param {string} xAlignContent - Align the content to left, center or right.
 * @param {string} yAlignContent - Align the content to top, center or bottom.
 */

const FlexibleWrapper = ({
    background,
    backgroundType,
    children,
    customGridSize,
    mediaQuery,
    paddingWrapper: PaddingWrapper,
    textColor,
    useGridWrapper,
    useMaxWidth,
    useOverlay,
    wrapperLink,
    xAlignContent,
    yAlignContent,
}) => {
    // Type is used by BackgroundSwitch to determine what component to use
    // Type can be a prop supplied to this component or a key:value inside the background object
    const type = backgroundType ? backgroundType : typeof background === 'object' ? background.type : 'color';

    // Colors
    const allowedColors = {
        white: colors.white,
        black: colors.black,
    };
    const finalColor = allowedColors[textColor] || allowedColors.white;

    return (
        <TextColorWrapper color={finalColor}>
            <WrapperLink volatile to={wrapperLink || undefined}>
                <BackgroundSwitch type={type} background={background} mediaQuery={mediaQuery}>
                    {useOverlay && <Overlay />}
                    {children && (
                        <PaddingWrapper>
                            <FlexibleContentPosition
                                customGridSize={customGridSize}
                                useGridWrapper={useGridWrapper}
                                useMaxWidth={useMaxWidth}
                                xAlignContent={xAlignContent}
                                yAlignContent={yAlignContent}
                            >
                                {children}
                            </FlexibleContentPosition>
                        </PaddingWrapper>
                    )}
                </BackgroundSwitch>
            </WrapperLink>
        </TextColorWrapper>
    );
};

FlexibleWrapper.propTypes = {
    background: backgroundFlexibleProp,
    backgroundType: backgroundTypeProp,
    children: PropTypes.node,
    customGridSize: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    mediaQuery: PropTypes.arrayOf(PropTypes.object),
    paddingWrapper: paddingOptionsProps,
    textColor: textColorProp,
    useGridWrapper: PropTypes.bool,
    useMaxWidth: PropTypes.bool,
    useOverlay: PropTypes.bool,
    wrapperLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    xAlignContent: horizontalAlignmentProp,
    yAlignContent: verticalAlignmentProp,
};

FlexibleWrapper.defaultProps = {
    background: 'white',
    backgroundType: undefined,
    children: undefined,
    customGridSize: undefined,
    mediaQuery: undefined,
    paddingWrapper: FlexiblePaddingWrapper,
    textColor: 'white',
    useGridWrapper: false,
    useMaxWidth: true,
    useOverlay: false,
    wrapperLink: undefined,
    xAlignContent: 'center',
    yAlignContent: 'center',
};

export default FlexibleWrapper;
