import FullScreenWrapper from 'components/wrappers/FullScreenWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import useHeaderHeights from 'hooks/useHeaderHeights';

/**
 * Used by the module system.
 * Makes sure that all contentBlocks have the same wrappers if they are used as a hero (first block on each page).
 * @param {node} children - The child component
 * @param {string} marginAfter - Used by ModuleMarginWrapper
 */

const HeroWrapper = ({ children, ...rest }) => {
    const { banner } = useHeaderHeights();

    return (
        <FullScreenWrapper offset={`${banner}px`} {...rest}>
            {children}
        </FullScreenWrapper>
    );
};

HeroWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default HeroWrapper;
