import PropTypes from 'prop-types';
import React from 'react';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';

/**
 * Create a wrapper that covers the whole viewport.
 * @param {bool} offset - Can be used to negate the height of the header, banner etc.
 */

const Wrapper = styled('div', { shouldForwardProp: prop => ['clientHeight', 'offset'].indexOf(prop) === -1 })`
    position: relative;
    width: 100vw;
    height: ${({ clientHeight, offset }) => (offset ? `calc(${clientHeight} - ${offset})` : clientHeight)};
`;

const FullScreenWrapper = ({ children, offset = '0', ...rest }) => {
    const clientHeight = useClientHeight();

    return (
        <Wrapper clientHeight={clientHeight} offset={offset} {...rest}>
            {children}
        </Wrapper>
    );
};

FullScreenWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    offset: PropTypes.string,
};

export default FullScreenWrapper;
