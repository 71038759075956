import CallToActionHeroBlock from 'libs/wordpress/content/cmsBlocks/CallToActionHeroBlock';
import HeroWrapper from 'components/wrappers/HeroWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import moduleMargins from 'config/theme/moduleMargins';
import styled from 'libs/styled';

const Section = styled('section')``;

const CallToActionHeroModule = ({ isHero = false, ...rest }) => {
    if (isHero) {
        return (
            <HeroWrapper as="section">
                <CallToActionHeroBlock isHero useGridWrapper useMaxWidth headingSize="lg" {...rest} />
            </HeroWrapper>
        );
    }

    return (
        <Section margin={moduleMargins.md}>
            <CallToActionHeroBlock useGridWrapper useMaxWidth headingSize="lg" {...rest} />
        </Section>
    );
};

CallToActionHeroModule.propTypes = {
    isHero: PropTypes.bool,
};

export default CallToActionHeroModule;
